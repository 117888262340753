<script setup lang="ts">
const properties = defineProps<{
  open: boolean
}>()

const emit = defineEmits({
  close: () => true,
  clickOutside: () => true
})

const handleCloseModal = () => {
  emit('close')
}

const clickOutside = () => {
  emit('clickOutside')
  handleCloseModal()
}
</script>

<template>
  <Teleport to="body">
    <div
      @click.self="clickOutside"
      class="fixed left-0 top-0 z-50 flex h-[calc(100dvh)] w-screen justify-center items-end overflow-y-scroll bg-[rgba(0,0,0,0.9)]"
      v-show="properties.open">
      <Transition :name="'modal-slide'">
        <dialog
          class="w-full translate-y-0 rounded-t-2xl bg-white text-center drop-shadow-[0px_20px_35px_rgba(2,80,75,0.3)]"
          v-if="properties.open"
          :open="properties.open">
          <div class="flex flex-col items-center px-4 py-5 text-emerald-950">
            <slot></slot>
          </div>
        </dialog>
      </Transition>
    </div>
  </Teleport>
</template>

<style>
.modal-slide-enter-active,
.modal-slide-leave-active {
  @apply transition-transform duration-300 ease-linear;
}

.modal-slide-enter-to,
.modal-slide-leave-from {
  @apply translate-y-0;
}

.modal-slide-leave-to,
.modal-slide-enter-from {
  @apply translate-y-full;
}
</style>
