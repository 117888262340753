import { defineStore } from 'pinia'

enum AuthState {
  Unknown,
  Authenticated,
  Unauthenticated
}

interface State {
  authenticated: AuthState
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): State => ({ authenticated: AuthState.Unknown }),
  getters: {
    isAuthenticated(): boolean {
      return this.authenticated === AuthState.Authenticated
    },
    isUnknown(): boolean {
      return this.authenticated === AuthState.Unknown
    }
  },
  actions: {
    setAuthenticated(authenticated: boolean) {
      this.authenticated = authenticated ? AuthState.Authenticated : AuthState.Unauthenticated
    }
  }
})
