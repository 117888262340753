// @ts-ignore
import App from '@/App.vue'
import { setI18nOptions } from '@/i18n/main'
import createRouterWithI18n from '@/router'
import Hotjar from '@hotjar/browser'
import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import { createI18n } from 'vue-i18n'

import '@/assets/main.css'
import '@/assets/tailwind.css'

// hotjar
const siteId = 3640944
const hotjarVersion = 6

export const app = createApp(App)
export const i18n = createI18n(setI18nOptions)
export const router = createRouterWithI18n(i18n)

Hotjar.init(siteId, hotjarVersion)
app.use(i18n)
app.use(createPinia())
app.use(VueAxios, axios)
app.use(router)
app.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_RAILS_BACKEND_URL === 'https://www.yago.be' ? 'GTM-KP7T6LRH' : ''
    }
  },
  router
)

app.mount('#app')
